import React from 'react'

import styled from 'styled-components'

import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl'

import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import { MainTitle } from 'src/components/static/common'

import { PricingBox } from 'src/components/static/pricingList'
import { PricingDiscountBox } from 'src/components/static/pricingDiscount'
import PricingUrl from 'src/assets/documents/cennik.pdf'

import { breakpoint } from 'src/theme/grid'
import EuFinancingLogoLarge from 'src/components/header/components/EuFinancingLogoLarge'
import EUFinancingDialog from 'src/components/EUFinancingDialog'

const Pricing = () => {
  const intl = useIntl()
  return (
    <ThemeProvider>
      <Layout>
        <PricingWrapper>
          <MainTitle>{intl.formatMessage({ id: 'pricing.title' })}</MainTitle>
          <PricingBoxContainer>
            <PricingBox
              bgColor='green'
              title={intl.formatMessage({ id: 'pricing.carrierTitle' })}
              subtitle={intl.formatMessage({ id: 'pricing.subTitleBasic' })}
              price={intl.formatMessage({ id: 'pricing.feeCarrierBasic' })}
              monthly={intl.formatMessage({ id: 'pricing.feeMonthly' })}
              listItems={[
                intl.formatMessage({ id: 'pricing.carrierBasicList1' }),
                intl.formatMessage({ id: 'pricing.carrierBasicList2' }),
                intl.formatMessage({ id: 'pricing.carrierBasicList3' })
              ]}
              buttonText={intl.formatMessage({ id: 'pricing.pricingButton' })}
            />
            <PricingBox
              bgColor='green'
              title={intl.formatMessage({ id: 'pricing.carrierTitle' })}
              subtitle={intl.formatMessage({ id: 'pricing.subTitlePro' })}
              price={intl.formatMessage({ id: 'pricing.feeCarrierPro' })}
              monthly={intl.formatMessage({ id: 'pricing.feeMonthly' })}
              listItems={[
                intl.formatMessage({ id: 'pricing.carrierProList1' }),
                intl.formatMessage({ id: 'pricing.carrierProList2' }),
                intl.formatMessage({ id: 'pricing.carrierProList3' })
              ]}
              buttonText={intl.formatMessage({ id: 'pricing.pricingButton' })}
            />
            <PricingBox
              bgColor='blue'
              title={intl.formatMessage({ id: 'pricing.forwarderTitle' })}
              subtitle={intl.formatMessage({ id: 'pricing.subTitleBasic' })}
              price={intl.formatMessage({ id: 'pricing.feeForwarderBasic' })}
              monthly={intl.formatMessage({ id: 'pricing.feeMonthly' })}
              listItems={[
                intl.formatMessage({ id: 'pricing.forwarderBasicList1' }),
                intl.formatMessage({ id: 'pricing.forwarderBasicList2' }),
                intl.formatMessage({ id: 'pricing.forwarderBasicList3' })
              ]}
              buttonText={intl.formatMessage({ id: 'pricing.pricingButton' })}
            />
            <PricingBox
              bgColor='blue'
              title={intl.formatMessage({ id: 'pricing.forwarderTitle' })}
              subtitle={intl.formatMessage({ id: 'pricing.subTitlePro' })}
              price={intl.formatMessage({ id: 'pricing.feeForwarderPro' })}
              monthly={intl.formatMessage({ id: 'pricing.feeMonthly' })}
              listItems={[
                intl.formatMessage({ id: 'pricing.forwarderProList1' }),
                intl.formatMessage({ id: 'pricing.forwarderProList2' }),
                intl.formatMessage({ id: 'pricing.forwarderProList3' })
              ]}
              buttonText={intl.formatMessage({ id: 'pricing.pricingButton' })}
            />
            <PricingBox
              bgMixed
              title={intl.formatMessage({
                id: 'pricing.carrierForwarderTitle'
              })}
              subtitle={intl.formatMessage({ id: 'pricing.subTitleBasic' })}
              price={intl.formatMessage({
                id: 'pricing.feeCarrierForwarderBasic'
              })}
              monthly={intl.formatMessage({ id: 'pricing.feeMonthly' })}
              listItems={[
                intl.formatMessage({
                  id: 'pricing.carrierForwarderBasicList1'
                }),
                intl.formatMessage({
                  id: 'pricing.carrierForwarderBasicList2'
                }),
                intl.formatMessage({ id: 'pricing.carrierForwarderBasicList3' })
              ]}
              buttonText={intl.formatMessage({ id: 'pricing.pricingButton' })}
            />
            <PricingBox
              bgMixed
              title={intl.formatMessage({
                id: 'pricing.carrierForwarderTitle'
              })}
              subtitle={intl.formatMessage({ id: 'pricing.subTitlePro' })}
              price={intl.formatMessage({
                id: 'pricing.feeCarrierForwarderPro'
              })}
              monthly={intl.formatMessage({ id: 'pricing.feeMonthly' })}
              listItems={[
                intl.formatMessage({ id: 'pricing.carrierForwarderProList1' }),
                intl.formatMessage({ id: 'pricing.carrierForwarderProList2' }),
                intl.formatMessage({ id: 'pricing.carrierForwarderProList3' })
              ]}
              buttonText={intl.formatMessage({ id: 'pricing.pricingButton' })}
            />
            {/* <PricingForeignCompanyBox  
              title={intl.formatMessage({id: 'pricing.foreignCompanies'})}
              subtitle={null}
              price={null}
              monthly={intl.formatMessage({id: 'pricing.foreignCompaniesNote'})}
              listItems={[
                // intl.formatMessage({ id: 'pricing.shippingProList1' }),
                // intl.formatMessage({ id: 'pricing.shippingProList2' }),
                // intl.formatMessage({ id: 'pricing.shippingProList3' })
              ]}
              buttonText={intl.formatMessage({id: 'pricing.foreignCompaniesContactUs'})}
              /> */}
          </PricingBoxContainer>

          <AdditionalAccountText>
            * {intl.formatMessage({ id: 'pricing.additionalAccount' })}
            {/* <strong>
              {intl.formatMessage({ id: 'pricing.additionalAccountPrice' })}
            </strong> */}
          </AdditionalAccountText>
          <PricingDiscountBoxContainer>
            <PricingDiscountBox
              greenBg
              title={intl.formatMessage({ id: 'pricing.pricingDiscountSmall' })}
              text={intl.formatMessage({
                id: 'pricing.pricingDiscountSmallText'
              })}
            />
            <PricingDiscountBox
              greenBg
              title={intl.formatMessage({
                id: 'pricing.pricingAffiliateProgramDiscountSmall'
              })}
              text2={intl.formatMessage({
                id: 'pricing.pricingAffiliateProgramDiscountSmallText'
              })}
              text={
                <FormattedHTMLMessage id='pricing.pricingAffiliateProgramDiscountSmallText' />
              }
            />
            {/* <PricingDiscountBox
              title={intl.formatMessage({ id: 'pricing.pricingDiscountBig' })}
              text={intl.formatMessage({
                id: 'pricing.pricingDiscountBigText'
              })}
            /> */}
          </PricingDiscountBoxContainer>
          {/* <PricingPdfWrapper>
            <PricingPdfText href={PricingUrl} target='_blank'>
              {intl.formatMessage({ id: 'pricing.PricingPdf' })} - PDF
            </PricingPdfText>
          </PricingPdfWrapper> */}
        </PricingWrapper>
        <EUFinancingDialog />
      </Layout>
      <EuFinancingLogoLarge />
    </ThemeProvider>
  )
}

export default Pricing

const PricingWrapper = styled.div`
  margin: 0 45px 70px;
  ${breakpoint.sm`
    margin: 0 50px 150px;
  `}
`

const PricingBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const PromoTitleWrapper = styled.h1`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  margin: 4rem 0;
  padding: 0 1rem;
  font-size: 20px;
  font-weight: 400;
  ${breakpoint.sm`
    font-size: 30px;
  `}
`
const PricingDiscountBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 40px;
`
const PricingPdfText = styled.a`
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const AdditionalAccountText = styled.div`
  text-align: center;
  font-size: 15px;
  ${breakpoint.sm`
font-size: 20px;
`}
`
const PricingPdfWrapper = styled.div`
  text-align: center;
  ${breakpoint.sm`
    text-align: left;
    margin-left: 100px;
    justify-content: flex-start;
  `}
`

const PricingForeignCompanyBox2 = styled.div`
  width: 100%;
  min-width: 270px;
  max-width: 270px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  padding: 0 28px;
  margin: 0;
  font-weight: 400;
  position: relative;
  margin: 15px;
  ${breakpoint.sm`
      width: 25%;
    `}
  &:before {
    content: '';
    background-color: ${({ greenBg }) => (greenBg ? '#428943' : '#1c98af')};
    background-size: contain;
    width: 15px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0;
  }
`
const PricingForeignCompanyBox = styled(PricingBox)`
  // //  height: 150px;
  // flex-direction: column;
  // // max-width: 60%;
  ${breakpoint.sm`
width: 25%;
`}
  &:before {
    content: '';
    background-color: #808285;
    // background-size: contain;
    // width: 15px;
    // height: 100%;
    // position: absolute;
    // left: 0px;
    // top: 0;
  }
`
