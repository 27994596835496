import React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { ButtonPrice } from 'src/components/atoms/Buttons'
import routes from 'src/utils/routes'
import { Link } from 'gatsby-plugin-intl'

const PricingListComponent = ({
  className,
  title,
  subtitle,
  price,
  monthly,
  listItems = [],
  buttonText
}) => (
  <div className={className}>
    <div>
      <div>
        <TitleWrapper>{title}</TitleWrapper>
        <SubTitleWrapper>{subtitle}</SubTitleWrapper>
      </div>
      {/* <div>
        <FeeWrapper>{price}</FeeWrapper>
      </div>
      <TextFeeWrapper>{monthly}</TextFeeWrapper> */}
      <br />
      {listItems.length > 0 && (
        <ListWrapper>
          <ul>
            {listItems.map(listItem => (
              <li>{listItem}</li>
            ))}
          </ul>
        </ListWrapper>
      )}
    </div>
    <ButtonWrapper>
      <Link to={routes.SIGN_UP}>
        <ButtonPrice active={true}>{buttonText}</ButtonPrice>
      </Link>
    </ButtonWrapper>
  </div>
)

const handlePricingBgColor = color => {
  switch (color) {
    case 'green':
      return '#428943'
    case 'blue':
      return '#1c98af'
    default:
      return '#428943'
  }
}

export const PricingBox = styled(PricingListComponent)`
  width: 100%;
  min-width: 230px;
  max-width: 230px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  padding: 0 28px;
  margin: 0;
  font-weight: 400;
  position: relative;
  margin: 15px;
  ${breakpoint.sm`
      width: 25%;
    `}
  &:before {
    content: '';
    background-color: ${({ bgColor }) => handlePricingBgColor(bgColor)};
    background-size: contain;
    width: 100%;
    height: 15px;
    position: absolute;
    top: -15px;
    left: 0;
    ${props =>
      props.bgMixed &&
      `
    background: linear-gradient(90deg, ${handlePricingBgColor(
      'green'
    )} 0%, ${handlePricingBgColor('blue')} 50%);
  `}
  }
`
const ListWrapper = styled.div`
  display: block;
  ul {
    padding-left: 2.8rem;
    list-style: none;
    li {
      margin-bottom: 0.6rem;
      position: relative;
      line-height: 1.6;
      font-size: 1.1rem;
      &:before {
        content: '';
        background-color: grey;
        background-size: contain;
        width: 5px;
        height: 5px;
        position: absolute;
        top: 14px;
        left: -15px;
      }
    }
  }
`
const TitleWrapper = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  padding: 23px 0 10px 0;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`
const SubTitleWrapper = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 10px;
  font-size: 20px;
  position: relative;
  text-align: center;
  &:after {
    content: '';
    background-color: #428943;
    background-size: contain;
    width: 80px;
    height: 2px;
    position: absolute;
    left: calc(50% - 38px);
    bottom: 0;
  }
`
const FeeWrapper = styled.div`
  text-transform: uppercase;
  font-size: 34px;
  padding-top: 25px;
  margin-bottom: 14px;
  text-align: center;
`
const TextFeeWrapper = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  padding-bottom: 40px;
  text-align: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
`
