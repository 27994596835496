import React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'

const PricingDiscountComponent = ({ className, title, text }) => (
  <div className={className}>
    <div>
      <div>
        <TitleWrapper>{title}</TitleWrapper>
      </div>
      <TextWrapper>{text}</TextWrapper>
    </div>
  </div>
)

export const PricingDiscountBox = styled(PricingDiscountComponent)`
  min-width: 260px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${({ greenBg }) => (greenBg ? '#e8f4e8' : '#d3ebef')};
  box-sizing: border-box;
  padding: 22px;
  margin: 15px 10px;
  font-weight: 400;
  position: relative;
  ${breakpoint.s`
      padding: 22px;
    `}
  ${breakpoint.sm`
      min-width: 455px;
      max-width: 50px;
      height: 367px;
      width: 50%;
      padding: 12px 54px;
    `}
`
const TitleWrapper = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 0 9px;
  font-size: 28px;
  position: relative;
  &:after {
    content: '';
    background-color: #428943;
    background-size: contain;
    width: 100px;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  ${breakpoint.sm`
      font-size: 40px;
    `}
`
const TextWrapper = styled.div`
  font-size: 16px;
  padding-top: 9px;
  ${breakpoint.sm`
      padding-bottom: 40px;
    `}
`
